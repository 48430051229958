import * as React from 'react';
import Header from '../../components/header/header';
import Seo from '../../components/seo/seo';
import PropTypes from 'prop-types';
import Footer from '../../components/footer/footer';

import * as styles from './landing-layout.module.scss';

const LandingLayout = ({ seo, lightHeader, useH1InHeader, landingElement, children }) => {
	return (
		<React.Fragment>
			<Seo {...seo} />
			<Header useH1={useH1InHeader} light={lightHeader} />

			<div className={styles.landingWrapper}>{landingElement}</div>

			<div className={styles.afterLandingWrapper}>
				<main>{children}</main>

				<Footer />
			</div>
		</React.Fragment>
	);
};

LandingLayout.defaultProps = {
	seo: {},
	lightHeader: false,
	useH1InHeader: false,
};

LandingLayout.propTypes = {
	seo: PropTypes.shape(Seo.propTypes),
	lightHeader: PropTypes.bool,
	useH1InHeader: PropTypes.bool,
	landingElement: PropTypes.element.isRequired,
};

export default LandingLayout;
