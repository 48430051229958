import * as React from 'react';
import { useRef } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Timeline, Tween } from 'react-gsap';
import { gsap } from 'gsap';

import * as styles from './landing-slider.module.scss';

const LandingSlider = ({ projects }) => {
	const intl = useIntl();
	const landingSlider = useRef(null);

	const onActionButtonClick = () => {
		gsap.to(window, {
			duration: 1,
			ease: 'power3.out',
			scrollTo: landingSlider?.current?.offsetTop + landingSlider?.current?.clientHeight,
		});
	};

	return (
		<div ref={landingSlider} className={styles.landingSliderContainer}>
			<Swiper
				slidesPerView={1}
				parallax={true}
				speed={810}
				autoplay={{
					delay: 3000,
				}}
				className={styles.landingSlider}
			>
				{projects?.map((project, i) => {
					const image = project?.gallery[0];

					return (
						<SwiperSlide key={`landingSlide${i}`} className={styles.projectSlide}>
							<GatsbyImage
								title={image?.meta?.title}
								alt={image?.meta?.alt ?? ''}
								image={getImage(image?.file)}
								className={styles.projectThumb}
							/>

							<div data-swiper-parallax="-200" className={styles.projectInfo}>
								<h2>{project?.title}</h2>

								<Link to={`/project/${project?.slug}`} className="btn-border-light">
									{intl.formatMessage({
										id: 'show-project-button',
										defaultMessage: 'Show project',
									})}
								</Link>
							</div>
						</SwiperSlide>
					);
				})}
			</Swiper>

			<Timeline
				target={
					<button
						className={styles.actionBtn}
						onClick={onActionButtonClick}
						aria-label={intl.formatMessage({
							id: 'aria-landing-slider-action-button-label',
							defaultMessage: 'Scroll down',
						})}
					>
						<i className="fal fa-chevron-double-down" />
					</button>
				}
				repeat={-1}
				repeatDelay={2}
			>
				<Tween from={{ y: 0 }} to={{ y: -30 }} duration={0.5} />
				<Tween to={{ y: 0 }} duration={2.5} ease="elastic.out(1, 0.25)" />
			</Timeline>
		</div>
	);
};

LandingSlider.defaultProps = {
	projects: [],
};

LandingSlider.propTypes = {
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			slug: PropTypes.string,
			gallery: PropTypes.arrayOf(
				PropTypes.shape({
					meta: PropTypes.object,
					file: PropTypes.object,
				})
			),
		})
	),
};

export default LandingSlider;
