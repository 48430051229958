import * as React from 'react';
import { useMemo } from 'react';
import { graphql } from 'gatsby';
import LandingLayout from '../layouts/landing-layout/landing-layout';
import ParsedContent from '../components/parsed-content/parsed-content';
import ProjectList from '../components/project-list/project-list';
import LandingSlider from '../components/landing-slider/landing-slider';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Link } from 'gatsby-plugin-react-intl';

import * as styles from '../styles/pages/index.module.scss';

const IndexPage = ({ data }) => {
	const intl = useIntl();
	const mapUrl = useMemo(() => {
		const url = data?.api?.hp?.mapCode?.match(
			/src="(https:\/\/(www\.)?google\.com\/maps\/embed.*?)"/
		);
		return url !== null && url?.length > 1 ? url[1] : null;
	}, [data]);

	return (
		<LandingLayout
			lightHeader={true}
			useH1InHeader={true}
			seo={{ description: data?.api?.seo?.siteDescription }}
			landingElement={<LandingSlider projects={data?.api?.hp?.sliderProjects ?? []} />}
		>
			<section className={styles.aboutUsSection}>
				<h2 className="lower">
					{intl.formatMessage({
						id: 'about-us-page-title',
						defaultMessage: 'About Us',
					})}
				</h2>

				<ParsedContent html={data?.api?.hp?.aboutUsText} />

				<Link to="/about-us" className="btn-border-dark">
					{intl.formatMessage({
						id: 'hp-about-us-more-btn',
						defaultMessage: 'More about us',
					})}
				</Link>
			</section>

			<section className={styles.selectedProjectsSection}>
				<h2 className="lower">
					{intl.formatMessage({
						id: 'projects-page-title',
						defaultMessage: 'Projects',
					})}
				</h2>

				<ProjectList singleRow={true} projects={data?.api?.hp?.selectedProjects ?? []} />

				<Link to="/projects" className="btn-border-dark">
					{intl.formatMessage({
						id: 'hp-projects-more-btn',
						defaultMessage: 'Show projects',
					})}
				</Link>
			</section>

			<section data-navigation-id="contact" className={styles.contactSection}>
				<h2 className="lower">
					{intl.formatMessage({
						id: 'hp-contact-title',
						defaultMessage: 'Contact us',
					})}
				</h2>

				<div className={styles.contactInfo}>
					<div className={styles.flexContainer}>
						{mapUrl && (
							<iframe
								title={intl.formatMessage({
									id: 'hp-map-iframe-title',
									defaultMessage: 'Map',
								})}
								loading="lazy"
								className={styles.map}
								allowFullScreen={true}
								src={mapUrl}
								role="application"
							/>
						)}

						{data?.api?.hp?.contactInfoLeft?.length > 0 && (
							<ParsedContent
								className={styles.contactInfoColumn}
								html={data?.api?.hp?.contactInfoLeft}
							/>
						)}

						{data?.api?.hp?.contactInfoRight?.length > 0 && (
							<ParsedContent
								className={styles.contactInfoColumn}
								html={data?.api?.hp?.contactInfoRight}
							/>
						)}
					</div>
				</div>
			</section>
		</LandingLayout>
	);
};

export const query = graphql`
	fragment ProjectFields on API_projectsCollectionLink {
		title
		slug
		gallery {
			path
			meta
			file {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	}

	query($language: String!) {
		api {
			seo: seoSingleton(lang: $language) {
				siteDescription
			}

			hp: homepageSingleton(lang: $language) {
				aboutUsText
				sliderProjects(lang: $language, sort: { _o: 1 }) {
					...ProjectFields
				}
				selectedProjects(lang: $language, sort: { _o: 1 }) {
					...ProjectFields
				}
				mapCode
				contactInfoLeft
				contactInfoRight
			}
		}
	}
`;

export default IndexPage;
